import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';  
import sanityClient from '../sanityClient';
import '../PressKitStyles.css';  

const PressKit = ({ isEnglish }) => {
  const [pressKitData, setPressKitData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "pressKit"][0]{
          title,
          title_jp,
          description,
          description_jp,
          files[] {
            fileName,
            fileUrl
          },
          trailer
        }`
      )
      .then((data) => setPressKitData(data))
      .catch(console.error);
  }, []);

  if (!pressKitData) return <div></div>;

  // Set the title and description based on the language
  const title = isEnglish ? pressKitData.title : pressKitData.title_jp;
  const description = isEnglish ? pressKitData.description : pressKitData.description_jp;

  return (
    <>
      {/* SEO and indexing control */}
      <Helmet>
        <title>Press Kit - Myosuki</title>
        <meta name="description" content="Download Myosuki's press kit, trailers, and other media files." />
        
        {/* Prevent indexing for the test site */}
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>

      <section className="press-kit-section">
        <h1>{title}</h1>
        <p>{description}</p>

        {/* Trailer Section */}
        <h2>Trailers</h2>
        {pressKitData.trailer ? (
          <iframe
            width="100%" 
            height="450px" 
            src={`https://www.youtube.com/embed/${new URL(pressKitData.trailer).searchParams.get('v')}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ border: 'none' }}  // This will remove the border
          ></iframe>
        ) : (
          <p>No trailer available</p>
        )}

        {/* Files Section */}
        <h2>Files</h2>
        {pressKitData.files && pressKitData.files.length > 0 ? (
          <div className="file-list">
            {pressKitData.files.map((file, index) => (
              <a key={index} href={file.fileUrl} download className="file-link">
                {file.fileName}
              </a>
            ))}
          </div>
        ) : (
          <p>No files available</p>
        )}

        {/* Download All Button */}
        {pressKitData.files && pressKitData.files.length > 0 && (
          <a href="/download-all" className="download-all-button">
            Download all
          </a>
        )}
      </section>
    </>
  );
};

export default PressKit;
