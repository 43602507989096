import React, { useState, useEffect } from 'react';
import '../AboutUsStyles.css';
import sanityClient from '../sanityClient';
import { Helmet } from 'react-helmet-async';  
import { FaTwitter, FaDiscord, FaEnvelope, FaYoutube } from 'react-icons/fa';

const AboutUs = ({ isEnglish }) => {
  const [aboutUsContent, setAboutUsContent] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "aboutUs"][0]{
          title,
          title_jp,
          description,
          description_jp,
          mainContent,
          mainContent_jp,
          image{
            asset->{
              url
            }
          },
          teamMembers[] {
            name,
            role,
            role_jp,
            image{
              asset->{
                url
              }
            },
            socialLinks {
              discordId,
              email,
              twitter,
              youtube
            }
          },
          govtInfo
        }`
      )
      .then((data) => setAboutUsContent(data))
      .catch((err) => console.error("Error fetching about us content:", err));
  }, []);

  if (!aboutUsContent) return <div></div>;

  const title = isEnglish ? aboutUsContent.title : aboutUsContent.title_jp;
  const description = isEnglish ? aboutUsContent.description : aboutUsContent.description_jp;
  const mainContent = isEnglish ? aboutUsContent.mainContent : aboutUsContent.mainContent_jp;

  const director = aboutUsContent.teamMembers.find(member => member.role === 'Studio Director');
  const otherTeamMembers = aboutUsContent.teamMembers.filter(member => member.role !== 'Studio Director');

  return (
    <section className="about-us-section">
      <Helmet>
        <title>About Us - Myosuki</title>
        <meta name="description" content="Learn more about Myosuki, our team, and mission." />
      </Helmet>

      <div className="about-us-container">
        <div className="about-us-image">
          <img src={aboutUsContent.image.asset.url} alt="About Us" />
        </div>
        <div className="about-us-text">
          <h1>{title}</h1>
          <h3>{description}</h3>
          <p>{mainContent}</p>
          {/* Display government information only in Japanese mode */}
          {!isEnglish && aboutUsContent.govtInfo && (
            <div className="govt-info">
              <h4>Government Information</h4>
              <p>{aboutUsContent.govtInfo}</p>
            </div>
          )}
        </div>
      </div>

      <div className="team-section">
        <h2>Our Team</h2>
        {director && (
          <div className="director">
            <div className="team-member">
              <div className="member-image">
                <img src={director.image.asset.url} alt={director.name} />
              </div>
              <h3 className="member-name">{director.name}</h3>
              <p className="member-role">{isEnglish ? director.role : director.role_jp}</p>
              <div className="member-social-icons">
                {director.socialLinks?.twitter && <a href={director.socialLinks.twitter}><FaTwitter /></a>}
                {director.socialLinks?.discordId && <a href={`https://discordapp.com/users/${director.socialLinks.discordId}`}><FaDiscord /></a>}
                {director.socialLinks?.email && <a href={`mailto:${director.socialLinks.email}`}><FaEnvelope /></a>}
                {director.socialLinks?.youtube && <a href={director.socialLinks.youtube}><FaYoutube /></a>}
              </div>
            </div>
          </div>
        )}

        <div className="team-container">
          {otherTeamMembers.map((member, index) => (
            <div className="team-member" key={index}>
              <div className="member-image">
                <img src={member.image.asset.url} alt={member.name} />
              </div>
              <h3 className="member-name">{member.name}</h3>
              <p className="member-role">{isEnglish ? member.role : member.role_jp}</p>
              <div className="member-social-icons">
                {member.socialLinks?.twitter && <a href={member.socialLinks.twitter}><FaTwitter /></a>}
                {member.socialLinks?.discordId && <a href={`https://discordapp.com/users/${member.socialLinks.discordId}`}><FaDiscord /></a>}
                {member.socialLinks?.email && <a href={`mailto:${member.socialLinks.email}`}><FaEnvelope /></a>}
                {member.socialLinks?.youtube && <a href={member.socialLinks.youtube}><FaYoutube /></a>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
