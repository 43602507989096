import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import '../GamesStyles.css';

const Games = ({ isEnglish }) => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "game"]{
          title,
          title_jp,
          releaseDate,
          releaseDate_jp,
          description,
          description_jp,
          "imageUrl": image.asset->url,
          "logoUrl": logo.asset->url,
          steamLink,
          learnMoreLink
        }`
      )
      .then((data) => setGames(data))
      .catch((error) => console.error('Error fetching games:', error));
  }, []);

  return (
    <section className="games-section">
      {games.map((game, index) => (
        <div className="game-card" key={index}>
          <div className="game-image">
            <img src={game.imageUrl} alt={game.title} />
          </div>
          <div className="game-content">
            <img src={game.logoUrl} alt={`${isEnglish ? game.title : game.title_jp} Logo`} className="game-logo" />
            <h3 className="release-date">{isEnglish ? game.releaseDate : game.releaseDate_jp}</h3>
            <p className="description">{isEnglish ? game.description : game.description_jp}</p>
            <div className="game-buttons">
              <a href={game.steamLink} target="_blank" rel="noopener noreferrer" className="btn btn-steam">
                {isEnglish ? 'Wishlist on Steam' : 'Steamのウィッシュリスト'}
              </a>
              <a href={game.learnMoreLink} className="btn btn-learn">
                {isEnglish ? 'Learn more' : 'もっと詳しく'}
              </a>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Games;
