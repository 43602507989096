import React, { useState, useEffect } from 'react';
import '../ContactUsStyles.css';
import sanityClient from '../sanityClient'; 
import { Helmet } from 'react-helmet-async'; 

const ContactUs = ({ isEnglish }) => {
  const [contactContent, setContactContent] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "contactUs"][0]{
          title,
          title_jp,
          description,
          description_jp,
          email,
          email_jp,
          image{
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => setContactContent(data))
      .catch((err) => console.error("Error fetching contact us content:", err));
  }, []);

  if (!contactContent) return <div></div>;

  // Determine if the text should be in English or Japanese
  const title = isEnglish ? contactContent.title : contactContent.title_jp;
  const description = isEnglish ? contactContent.description : contactContent.description_jp;
  const email = isEnglish ? contactContent.email : contactContent.email_jp;

  return (
    <section className="contact-us-section">
      {/* Helmet used to control the page title and meta tags for SEO */}
      <Helmet>
        <title>Contact Us - Myosuki</title>
        <meta name="description" content="Get in touch with the Myosuki team via email." />

        {/* This tag will prevent indexing on the test site, but allow it on the live site */}
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>

      <div className="contact-us-container">
        {/* Conditionally render the image only if it exists */}
        {contactContent.image && contactContent.image.asset && (
          <div className="contact-us-image">
            <img src={contactContent.image.asset.url} alt="Contact Us" />
          </div>
        )}

        {/* Display the text content */}
        <div className="contact-us-text">
          <h1>{title}</h1>
          <h3>{email}</h3>
          <p>{description}</p>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
