import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';  
import '../PolicyStyles.css'; 
import sanityClient from '../sanityClient'; 
import { PortableText } from '@portabletext/react'; 

const Policy = ({ isEnglish }) => {
  const [policyContent, setPolicyContent] = useState(null);
  const [activeSection, setActiveSection] = useState(0); // Track active section

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "policy"][0]{
          title,
          title_jp,
          sections[]{
            title,
            title_jp,
            content,
            content_jp
          },
          image{
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => setPolicyContent(data))
      .catch(console.error);
  }, []);

  if (!policyContent) return <div></div>;

  const title = isEnglish ? policyContent.title : policyContent.title_jp;
  const sections = policyContent.sections || [];

  const handleSectionChange = (index) => {
    setActiveSection(index);
  };

  return (
    <>
      {/* SEO and indexing control */}
      <Helmet>
        <title>Policy - Myosuki</title>
        <meta name="description" content="Read our policies, including privacy, IP guidelines, and more." />
        
        {/* Prevent indexing for the test site */}
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>

      <section className="policy-section">
        <div className="policy-container">
          {/* Conditionally render the image */}
          {policyContent.image && (
            <div className="policy-image">
              <img src={policyContent.image.asset.url} alt="Policy" />
            </div>
          )}

          <div className="policy-text">
            <h1>{title}</h1>
            
            {/* Sub-menu */}
            <nav className="policy-menu">
              <ul>
                {sections.map((section, index) => (
                  <li key={index} className={activeSection === index ? 'active' : ''}>
                    <button onClick={() => handleSectionChange(index)}>
                      {isEnglish ? section.title : section.title_jp}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            {/* Display active section content */}
            <div className="policy-content">
              <PortableText value={isEnglish ? sections[activeSection].content : sections[activeSection].content_jp} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Policy;
