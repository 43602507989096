import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import { Helmet } from 'react-helmet-async';
import sanityClient from '../sanityClient';
import languageEnIcon from '../assets/language-en.png';
import languageJpIcon from '../assets/language-jp.png';
import logo from '../assets/myosuki-logo.png';
import lightModeIcon from '../assets/icon_light.png'; 
import darkModeIcon from '../assets/icon_dark.png';

// Header component that manages language toggle, dark mode, and menu state
const Header = ({ isEnglish, setIsEnglish }) => {
  const [headerContent, setHeaderContent] = useState(null); // State to store header text content
  const [isDarkMode, setIsDarkMode] = useState(false); // State to toggle dark mode
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the menu open/close

  // Fetch header content (text for different pages) from Sanity
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "headerText"][0]{
          english,
          japanese
        }`
      )
      .then((data) => setHeaderContent(data))
      .catch(console.error);
  }, []);

  // Check local storage for saved dark mode preference on initial load
  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    if (savedDarkMode) {
      document.body.classList.add('dark-mode');
    }
  }, []);

  // Function to toggle between English and Japanese
  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  // Function to toggle dark mode and save preference in local storage
  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    if (newDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  // Function to open/close the mobile menu and lock scrolling when open
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      // Lock scroll when menu opens
      document.body.style.overflow = 'auto'; 
      document.documentElement.style.overflowX = 'auto';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      // Restore scroll when menu closes
      document.body.style.overflow = 'auto'; 
      document.documentElement.style.overflowX = 'auto';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  };

  // Render nothing if header content is not yet loaded
  if (!headerContent) return <div></div>;

  // Set the displayed text based on the language selection
  const text = isEnglish ? headerContent.english : headerContent.japanese;

  return (
    <>
      {/* Helmet manages SEO and page metadata */}
      <Helmet>
        <title>Myosuki - Home</title>
        <meta name="description" content="Welcome to Myosuki. Explore our content, news, and team." />
        {/* Prevent indexing on test subdomain */}
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>

      {/* Header structure with conditional fixed positioning when menu is open */}
      <header className={`header ${isMenuOpen ? 'fixed-header' : ''}`}>
        <div className="container">
          {/* Logo linking to the homepage */}
          <Link to="/" className="logo">
            <img src={logo} alt="Myosuki Logo" className="logo-image" />
          </Link>

          {/* Navigation menu */}
          <div className="menu-container">
            <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
              {text.shop && (
                <a href={text.shop} className="nav-item" target="_blank" rel="noopener noreferrer" onClick={() => setIsMenuOpen(false)}>
                  {isEnglish ? 'Shop' : 'ショップ'}
                </a>
              )}
              <Link to="/games" className="nav-item" onClick={() => setIsMenuOpen(false)}>{text.games}</Link>
              <Link to="/news" className="nav-item" onClick={() => setIsMenuOpen(false)}>{text.news}</Link>
              <Link to="/about" className="nav-item" onClick={() => setIsMenuOpen(false)}>{text.about}</Link>
              <Link to="/contact" className="nav-item" onClick={() => setIsMenuOpen(false)}>{text.contact}</Link>
              <Link to="/press-kit" className="nav-item" onClick={() => setIsMenuOpen(false)}>{text.pressKit}</Link>
              <Link to="/policy" className="nav-item" onClick={() => setIsMenuOpen(false)}>{text.policy}</Link>
            </nav>

            {/* Icons for language and dark mode toggle */}
            <div className="icon-group">
              <div className="language-toggle" onClick={toggleLanguage}>
                <img
                  src={isEnglish ? languageJpIcon : languageEnIcon}
                  alt={isEnglish ? "Switch to Japanese" : "Switch to English"}
                  className="language-icon"
                />
              </div>

              <button className="dark-mode-toggle" onClick={toggleDarkMode}>
                <img
                  src={isDarkMode ? darkModeIcon : lightModeIcon}
                  alt={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
                  className="dark-mode-icon" 
                  style={{ width: "30px", height: "30px" }} 
                />
              </button>
            </div>
          </div>

          {/* Hamburger menu icon for mobile navigation */}
          <button className="hamburger-menu" onClick={toggleMenu}>
            {!isMenuOpen ? (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M3 6h18M3 12h18M3 18h18" stroke="#ffffff" strokeWidth="2" />
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M6 6l12 12M6 18L18 6" stroke="#ffffff" strokeWidth="2" />
              </svg>
            )}
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;
