import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../sanityClient';
import { PortableText } from '@portabletext/react'; 
import { Helmet } from 'react-helmet-async';  
import '../NewsPostStyles.css';  

const NewsPost = ({ isEnglish }) => {  
  const { slug } = useParams(); 
  const [post, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost" && slug.current == $slug]{
          title,
          title_jp,  // Japanese title
          date,
          mainContent,
          mainContent_jp,  // Japanese main content
          image{
            asset->{
              url
            }
          }
        }`,
        { slug }
      )
      .then((data) => setPost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!post) return <div></div>;

  // Determine which language content to show based on isEnglish
  const title = isEnglish ? post.title : post.title_jp;  
  const mainContent = isEnglish ? post.mainContent : post.mainContent_jp;  // content toggle

  return (
    <>
      {/* SEO and indexing control */}
      <Helmet>
        <title>{title} - Myosuki News</title>
        <meta name="description" content={`Read the latest updates: ${title}`} />

        {/* Prevent indexing for the test site */}
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}

        {/* Structured Data (Schema.org for NewsArticle) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": title,
            "image": post.image.asset.url,
            "datePublished": new Date(post.date).toISOString(),
            "author": {
              "@type": "Person",
              "name": "Myosuki Team"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Myosuki",
              "logo": {
                "@type": "ImageObject",
                "url": "https://myosuki.com/Resources/Buttons%20&%20Icons/myosuki-favicon/favicon.png"  // Replace with your actual logo URL
              }
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://myosuki.com/news/${slug}`
            }
          })}
        </script>
      </Helmet>

      <article className="news-post">
        <img src={post.image.asset.url} alt={title} className="news-post-image" /> 
        <h1>{title}</h1>  {/* Displaying the appropriate title */}
        <p>{new Date(post.date).toLocaleDateString()}</p>
        
        {/* Use PortableText to render the rich text content */}
        <div className="news-post-content">
          <PortableText value={mainContent} />  
        </div>
      </article>
    </>
  );
};

export default NewsPost;
