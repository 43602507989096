import { createClient } from '@sanity/client';

const sanityClient = createClient({
  projectId: 'htee2all', // Use your actual project ID from Sanity
  dataset: 'production', // dataset name
  apiVersion: '2024-10-04', 
  useCdn: true, // `false` if you want to ensure fresh data
});

export default sanityClient;
