import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; 
import '../NewsPageStyles.css';

const NewsPage = ({ isEnglish }) => {
  const [newsPosts, setNewsPosts] = useState([]);

  // Fetch the news posts from Sanity
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost"] | order(date desc){
          title,
          title_jp,
          date,
          slug,
          shortDescription,
          shortDescription_jp,
          image{
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => setNewsPosts(data))
      .catch(console.error);
  }, []);

  if (!newsPosts.length) {
    return <div></div>;
  }

  return (
    <>
      {/* SEO and indexing control */}
      <Helmet>
        <title>News - Myosuki</title>
        <meta name="description" content="Stay updated with the latest news from Myosuki." />
        
        {/* Prevent indexing for the test site */}
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>

      <section className="news-page">
        <h1 className="news-title">{isEnglish ? 'News' : 'ニュース'}</h1>
        <div className="news-list">
          {newsPosts.map((post, index) => (
            <Link key={index} to={`/news/${post.slug.current}`} className="news-item">
              <img src={post.image.asset.url} alt={isEnglish ? post.title : post.title_jp} className="news-image" />
              <div className="news-content">
                <p className="news-date">{new Date(post.date).toLocaleDateString()}</p>
                <h2 className="news-heading">{isEnglish ? post.title : post.title_jp}</h2>
                <p className="news-summary">{isEnglish ? post.shortDescription : post.shortDescription_jp}</p>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};

export default NewsPage;
