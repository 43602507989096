import React, { useState, useEffect } from 'react';
import './styles.css';
import Header from './components/Header';
import LandingSection from './components/LandingSection';
import NewsPage from './components/NewsPage';
import NewsSection from './components/NewsSection';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import NewsPost from './components/NewsPost';
import Policy from './components/Policy';
import ContactUs from './components/ContactUs';
import PressKit from './components/PressKit';
import TwitterFeed from './components/TwitterFeed';
import Games from './components/Games';
import Skeleton from './components/Skeleton';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import sanityClient from './sanityClient';

function App() {
  const [isEnglish, setIsEnglish] = useState(true);
  const [metaData, setMetaData] = useState({
    title: 'Myosuki',
    description: 'Welcome to Myosuki, where love and laughter intertwine, and romance meets comedy. Explore our latest updates and heartwarming stories.',
    image: 'https://opengraph.b-cdn.net/production/images/9724ac33-3935-4464-9238-40905f1c6176.png?token=hMXInbm9VolUvZ-Nvc7GAo-bHo-wudn9NX2N_85XX5A&height=696&width=1200&expires=33266949894',
    url: 'https://test.myosuki.com'
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate initial loading delay and fetch data
    setTimeout(() => {
      setIsLoading(false);
    }, 1500); // Adjust the time as needed

    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage) {
      setIsEnglish(savedLanguage === 'english');
    } else {
      axios.get('https://ipapi.co/json/')
        .then(response => {
          const country = response.data.country;
          if (country === 'JP') {
            setIsEnglish(false);
            localStorage.setItem('preferredLanguage', 'japanese');
          } else {
            setIsEnglish(true);
            localStorage.setItem('preferredLanguage', 'english');
          }
        })
        .catch(error => {
          console.error('Error fetching location:', error);
          setIsEnglish(true);
          localStorage.setItem('preferredLanguage', 'english');
        });
    }

    sanityClient
      .fetch(
        `*[_type == "siteMeta"][0]{
          title,
          description,
          "imageUrl": image.asset->url,
          url
        }`
      )
      .then((data) => {
        if (data) {
          setMetaData((prevMetaData) => ({
            title: data.title || prevMetaData.title,
            description: data.description || prevMetaData.description,
            image: data.imageUrl || prevMetaData.image,
            url: data.url || prevMetaData.url
          }));
        }
      })
      .catch(console.error);
  }, []);

  const toggleLanguage = () => {
    const newLanguage = !isEnglish ? 'english' : 'japanese';
    setIsEnglish(!isEnglish);
    localStorage.setItem('preferredLanguage', newLanguage);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="test.myosuki.com" />
        <meta property="twitter:url" content={metaData.url} />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.image} />
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>

      <Router>
        <div className="App">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <Header isEnglish={isEnglish} setIsEnglish={toggleLanguage} />
              <div className="app-container"> {/* Added wrapper for layout control */}
                <main className="content">
                  <Routes>
                    <Route path="/" element={
                      <>
                        <LandingSection isEnglish={isEnglish} />
                        <NewsSection isEnglish={isEnglish} />
                        <TwitterFeed />
                      </>
                    } />
                    <Route path="/news" element={<NewsPage isEnglish={isEnglish} />} />
                    <Route path="/news/:slug" element={<NewsPost isEnglish={isEnglish} />} />
                    <Route path="/about" element={<AboutUs isEnglish={isEnglish} />} />
                    <Route path="/policy" element={<Policy isEnglish={isEnglish} />} />
                    <Route path="/contact" element={<ContactUs isEnglish={isEnglish} />} />
                    <Route path="/press-kit" element={<PressKit isEnglish={isEnglish} />} />
                    <Route path="/Games" element={<Games isEnglish={isEnglish} />} />
                  </Routes>
                </main>
                <Footer /> {/* Footer is part of the app-container to stay at bottom */}
              </div>
            </>
          )}
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
