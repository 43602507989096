import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';  
import '../TwitterFeedStyles.css';

const TwitterFeed = () => {
  const [twitterLoaded, setTwitterLoaded] = useState(false);
  const [twitterError, setTwitterError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect mobile screen size

  useEffect(() => {
    const loadTwitterScript = () => {
      if (window.twttr) {
        window.twttr.widgets.load();
        setTwitterLoaded(true);
      } else {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        script.onload = () => setTwitterLoaded(true);
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    };

    loadTwitterScript();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust based on screen width
    };

    window.addEventListener('resize', handleResize);

    const timeoutId = setTimeout(() => {
      if (!twitterLoaded) {
        setTwitterError(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [twitterLoaded]);

  return (
    <>
      <Helmet>
        <title>Myosuki Twitter Feed</title>
        <meta name="description" content="Stay updated with the latest posts and tweets from Myosuki." />
        {window.location.hostname.includes('test.myosuki.com') ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>

      <section className="twitter-section">
        <div className="twitter-feed">
          <h2>Posts from @Myosuki</h2>

          {twitterError ? (
            <div className="twitter-feed-wrapper">
              <a
                href="https://twitter.com/myosuki"
                className="twitter-error-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Our Twitter feed couldn't load. Click here to visit Twitter directly. Please disable browser extensions like Privacy Badger that block this content.
              </a>
            </div>
          ) : (
            <div className="twitter-feed-wrapper">
              <a
                className="twitter-timeline"
                data-lang="en"
                data-theme="light"
                data-tweet-limit="3"
                data-width={isMobile ? '100%' : '600'}
                data-height={isMobile ? '300' : '500'}
                href="https://twitter.com/myosuki?ref_src=twsrc%5Etfw"
              >
                Tweets by @Myosuki
              </a>
            </div>
          )}

          <div className="twitter-follow">
            <a href="https://twitter.com/myosuki" className="twitter-follow-button" data-show-count="false">
              Follow on X
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default TwitterFeed;
